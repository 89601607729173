<template>
  <div>
    <div id="step1">
      <div id="first_row" class="row justify-content-center align-items-center">
        <pelotitaspasos :infostep="'Datos del auto'" />
      </div>
      <fieldset :class="{ cambio_paso: transicion }">
        <div class="row justify-content-center pt-2 d-none d-lg-flex hidden-md-and-down">
          <div class="col-10 col-sm-7 col-md-6 col-lg-10 col-xl-10">
            <div class="row justify-content-center">
              <div class="text-center">
                <img src="../../assets/img/mujer.png" class="image-icon" alt="Mujer"/>
              </div>
            </div>
          </div>
        </div>
        <div id="fourth_row_forms" class="row justify-content-center pt-4">
          <div class="col-11 col-xl-9 mt-md-5 mt-lg-2 mt-sm-1 mt-xl-2">
            <div class="row justify-content-center">
              <p class="text-center slogan1 d-block d-sm-block d-md-none d-lg-none d-xl-none">Indica los siguientes<br>datos de tu auto</p>
              <p class="text-center slogan1 d-none d-sm-none d-md-block d-lg-block d-xl-block pt-xl-3">Indica los siguientes datos de tu auto</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <!--Marca-->
          <div class="col-lg-7 col-xl-4 col-md-7 col-sm-8 col-11 p-2 autocompletar">
            <select v-model="marca_coche" 
              id="typeahead_marcas" 
              class="input-seleccionada"
              :style="marca_coche != 0 ? 'background-color: #fff;color: #4e789b;' : ''"
              @change="setMarcaCoche(marca_coche)" >
              <option value="0">Selecciona marca</option>
              <option v-for="m in catalogo_marcas" :key="m.id" :value="m.id" >{{ m.marca }}</option>
            </select>
          </div>

          <div class="col-lg-7 col-xl-4 col-md-7 col-sm-8 col-11 p-2 autocompletar ">
            <select v-model="modelo_coche" 
              id="typeahead_modelos" 
              class="input-seleccionada" :style="marca_coche !== 0 ? (modelo_coche !== 0 ? 'background-color: #fff; color: #4e789b;' : 'background-color: #fff;') : ''"
              :disabled="marca_coche == 0 || marca_coche == '0'" @change="setModeloCoche(modelo_coche)">
              <option value="0">Selecciona modelo</option>
              <option v-for="model in catalogo_modelos" :key="model.ids" :value="model.ids">{{ model.modelo }}</option>
            </select>
          </div>

        </div>
        <div class="row justify-content-center">
          <div class="col-lg-7 col-xl-4 col-md-7 col-sm-8 col-11 p-2 autocompletar">
            <select v-model="anoSeleccionado" id="selectyears" 
            class="input-seleccionada" :style="modelo_coche !== 0 ? (anoSeleccionado !== 0 ? 'background-color: #fff; color: #4e789b;' : 'background-color: #fff;') : ''"
            :disabled="modelo_coche == 0" @change="setAnoCoche(anoSeleccionado)">
              <option value="0">Selecciona año</option>
              <option v-for="ano in anosDisponibles" :key="ano" :value="ano">{{ ano }}</option>
            </select>
          </div>
          <div class="col-lg-7 col-xl-4 col-md-7 col-sm-8 col-11 p-2 autocompletar d-none" v-if="this.versions">
            <select
              v-if="anoSeleccionado != '0' && anoSeleccionado != ''"  
              v-model="version_coche"  
              id="typeahead_versions"  
              class="input-seleccionada" :style="anoSeleccionado !== 0 ? (version_coche !== 0 ? 'background-color: #fff; color: #4e789b;' : 'background-color: #fff;') : ''"
              @change="setVersionCoche(version_coche)"
              >
              <option value="0">Selecciona Version</option>
              <optgroup v-for="(versiones, modelo) in versions" :key="modelo" :label="modelo">
                <option v-for="version in versiones" :key="version" :value="version.id"> {{ version.version.replace(/\bano\b/g, 'año') }}</option>
              </optgroup>
              <option value="no se">No sé</option>

            </select>
          </div>

        </div>
        <div id="fourth_row_form" class="row justify-content-center">
          <div class="col-11 col-xl-9 mt-md-5 mt-lg-2 mt-sm-1 mt-xl-2">
            <div class="row justify-content-center mt-0">
              <p class="text-center slogan2">
                ¿Cuantos kilómetros tiene?
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-7 col-xl-4 col-md-7 col-sm-8 col-11 p-2 autocompletar">
            <input
              v-model="kms_coche"
              type="number"
              maxlength='6'
              class="input-seleccionada"
              :style="this.isDisabledKm() ? 'background-color: #fff; color: #4e789b; font-family: Montserrat-Medium' : ''"
              placeholder="Ej. 150000"
              pattern="[0-9]{6}"
              title="Ingrese 6 dígitos numéricos"
              id="input_km"
              ref="input_km"
              name="input_km"
              :disabled="!this.isDisabledKm()"        
              />
            <p v-if="errors.kilometraje" class="error">
              * Completa todas las casillas para avanzar
            </p>
          </div>
        </div>

        <div class="text-center mx-5 mt-3">
          <p class="nota1 mb-0">
            * Si tu auto no aparece en las opciones, ponte en contacto con nosotros <br /> por <a href="tel:+525555661583">llamada</a> o por <a target="_blank" href="https://api.whatsapp.com/send?l=es&phone=5215612673878">WhatsApp</a>
          </p>
        </div>

        <div class="row justify-content-center">
          <div id="sixth_row_form" class="row justify-content-center pt-3 pb-2">
            <div id="boton_continuar" class="col-12">
              <button type="button" class="btn-orange center" @click="comprobarDatos()" :disabled="kms_coche == '' || marca_coche == 0 || modelo_coche == 0 || anoSeleccionado == 0">
                Continuar
                <svg style="margin-left: 15px" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53636 0L0 1.48571L5.04805 6.36735L0 11.249L1.53636 12.7347L8.12078 6.36735L1.53636 0Z" fill="white" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="row justify-content-center" >
      <div class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
        <BotonVolver ></BotonVolver>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import { mapState } from "vuex";
import BotonVolver from "./BotonVolver.vue";
import pelotitaspasos from "./PelotitasPasos.vue";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";

export default {
  name: "Datos del auto",
  components: { 
    pelotitaspasos , BotonVolver
  },
  store,
  computed: {
    ...mapState({
     step: (state) => state.formulario.step,
     api_token: (state) => state.api_token,
     formulario: (state) => state.formulario,
     data_matricula: (state) => state.formulario.data_matricula,
     solicitud: (state) => state.solicitud,
     preload: (state) => state.preload,
     marca: (state) => state.formulario.data_matricula.marca,
     modelo: (state) => state.formulario.data_matricula.modelo,
     version: (state) => state.formulario.data_matricula.version,
     fecha_matriculacion: (state) => state.formulario.data_matricula.fecha_matriculacion,
     kms: (state) => state.formulario.kms
   }),
    catalogo_marcas: function () {
      return this.marcas;
    },
    catalogo_modelos: function () {
      return this.modelos;
    },
    catalogo_versiones: function () {
      return this.versions;
    }
  },
  data: function () {
    return {
      marca_coche: store.state.formulario.data_matricula.marca,
      modelo_coche: store.state.formulario.data_matricula.modelo,
      version_coche: store.state.formulario.data_matricula.version,
      kms_coche: store.state.formulario.kms,
      anoSeleccionado: store.state.formulario.data_matricula.fecha_matriculacion,
      anosDisponibles: [],
      codigo: "",
      marcas: [],
      modelos: [],
      versions: null,
      transicion: false,
      windowWidth: window.innerWidth,
      campos: false,
      errors: {
        marca: false,
        modelo: false,
        fecha: false,
        potencia: false,
        nolose: false,
        kilometraje:false
      },
      styleObject: {
        color: 'red',
        fontSize: '13px'
      }
    };
  },
  methods: {

    getMarcas:function () {
      axios.post(process.env.VUE_APP_AXIOS_BASE_URL + "mexico/getMarcas").then((resp) => {
        this.marcas = resp.data;
      });
    },

    getModelos: function () {
      axios.post(process.env.VUE_APP_AXIOS_BASE_URL + "mexico/getModelos/" + this.marca_coche).then((resp) => {
        this.modelos =  resp.data;
      });
    },

    getLimitsAnios: async function() {
      await axios.post(`${process.env.VUE_APP_AXIOS_BASE_URL}mexico/getLimitsAnios`, { modelo: this.modelo_coche })
      .then( ( resp ) => {
        // Se obtienen los años en los que el auto estuvo disponible dentro del cataálogo de autometrica
        const { years } = resp.data;  
        const currentYear = new Date().getFullYear(); 
        const limitAutometrica = 12; // Autometrica solo considera 12 años
        const limitYear = currentYear - limitAutometrica; 
        const limit = 2005; // Se está considerando como el año mínimo el 2005
        const catalogo = [];
        for (let index = limitYear; index > limit; index--) {
          catalogo.push(index.toString());
        }
        catalogo.push('2005 o anterior'); // Se agrega la opción 2005 o anterior
        // Se usa set para evitar duplicados
        // Se concatena los años mandados por API y los años de nuestro catalogo de año mínimo hasta 2005
        this.anosDisponibles = [...new Set(years.concat(catalogo))];
      });
    },

    getVersions: function () {
      axios.post(process.env.VUE_APP_AXIOS_BASE_URL + "mexico/getVersiones/" + this.fecha_matriculacion, {'ids': this.modelo})
        .then(response => {
          if (response.data.length < 1) {
            this.versions = null
            this.campos = true;
            this.version_coche = 0
            this.setVersionCoche(this.versions)
          }else{
            this.campos = false;
            this.version_coche = '0'
            this.versions = response.data;
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },

    isDisabledKm: function () {
      return this.anoSeleccionado !== 0 && this.campos === true;
      // return (this.version_coche != 0 || this.version_coche != '0') || (this.campos == true && this.versions == null);
    },

    comprobarDatos() {
      if (this.kms_coche != 0 ) {
        store.commit("setKms",this.kms_coche);
        this.$router.push('/formulario/step3');
      } else {

        this.$router.push('/formulario/step2');
      }
    },

    setMarcaCoche(id) {
      store.commit("setMarca", id);
      this.modelo_coche = 0
      this.anoSeleccionado = 0
      parseInt(this.anoSeleccionado)  > 2010 ? this.version_coche = '0'  : 0
      this.getModelos();
    },

    setModeloCoche(id) {
      store.commit("setModelo", id);
      this.getLimitsAnios();
      if(this.anoSeleccionado != 0){
        this.getVersions();
      }
    },

    setAnoCoche(year) {
      store.commit("setAno", year);
      // parseInt(year)  > 2010 && this.versions != null ? this.version_coche = '0'  : 0
      // parseInt(year)  < 2010 ? this.versions = null : this.versions
      // this.getVersions();
      if(year){
        this.campos = true;
      }
    },

    setVersionCoche(version) {
      store.commit("setVersion", version);
    },

    setKmsCoche(kms) {
      store.commit("setKms", kms);
    },

    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

  },

  created: function () {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'datos_auto',
    });
    this.getMarcas();
    if (this.modelo_coche != 0) {
      this.getModelos();
    }
    if (this.version_coche != 0) {
      this.getVersions();
    }
  },

  mounted: function () {
    store.commit("setBarraColor1", "azul");
    store.commit("setCheckColor2", "azul-2");
    store.commit("setBarraColor2", "blanco");
    store.commit("setCheckColor3", "blanco");
    this.codigo = this.$route.params.codigo;
    // Si no viene código, redirigir al primer paso

    if (!isNaN(this.codigo) || !this.codigo == "") {
      // Buscar id solicitud
      var idSolicitud = this.codigo.slice(14);
      store.commit("setTokenEnlace", this.codigo);
      store.commit("setIdSolicitud", idSolicitud);
    }

    store.commit("setPreload", false);
    const menu = document.querySelector('#navigation_menu');

    if (window.innerWidth >= 992) {
      menu.style.display = 'block';
    }else{
      menu.style.display = 'none';
    }

    window.addEventListener('resize', this.getWindowWidth);
  },

  watch: {
    windowWidth(value) {
      const menu = document.querySelector('#navigation_menu');
      if (value >= 992) {
        menu.style.display = 'block';
      }else{
        menu.style.display = 'none';
      }
    },
  },
};
</script>

<style scoped lang="scss">

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.nota1{
  font-family: 'Montserrat-Medium';
  color: #004680;
  font-style: italic;
}
.btn-orange {
  font-family: 'Montserrat-Medium';
}

.slogan1 {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #004680;
}

.slogan2 {
  color: black;
  font-size: 18px;
  font-family: "montserrat-bold";
}

.autocompletar:deep() 

.input-seleccionada{
    border-radius: 10px;
    border: 2px solid #c4c4c4;
    display: flex;
    width: 100%;
    height: 48px;
    padding: 11px 15px;
    align-items: center;
    gap: 10px;
    color: #a5a5a5;
    font-family: "Montserrat-Medium";
    font-size: 18px;
    font-style: normal;
    line-height: normal;
  }

#step1 {
  #image_formulario {
    background-repeat: no-repeat;
    background-size: cover;
  }
  #cards_row_form {
    margin-top: 20px;
  }
}

#typeahead_marcas {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../assets/img/Vector.svg") !important;
    background-position: 95% 50%;
    background-color: #ffffff; 
    background-repeat: no-repeat;
    background-clip: content-box;
    background-size: 1.1rem;
    padding: 0px 0px 0px 16px;
    border: 2px solid #c4c4c4;
  }
  #typeahead_marcas option{
    background-color:white;
    color:#004680;
  }

  #typeahead_marcas:active,
  #typeahead_marcas:focus{
    border:none;
    color: #004680;
    border: 2px solid #c4c4c4;
  }
  #typeahead_marcas:checked
  {
    color: #004680;
    background-color: #ffffff !important; /* Cambia el color de fondo al seleccionar una opción */
    border-radius: 10px;
    background-image: url("../../assets/img/Vector2.svg") !important;
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 1.1rem;
    padding: 0px 0px 0px 16px;
    border: none;
  }

  #typeahead_modelos {
    background-color: rgba(196, 196, 196, 0.30);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../assets/img/Vector.svg") !important;
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 1.1rem;
    padding: 0px 0px 0px 16px;
  }
  #typeahead_modelos option{
    background-color:white;
    color:#004680;
  }

  #typeahead_modelos:focus,
  #typeahead_modelos:checked,
  #typehead_modelos:active {
    color: #004680;
    background-color: #ffffff !important; /* Cambia el color de fondo al seleccionar una opción */
    border-radius: 10px;
    background-image: url("../../assets/img/Vector2.svg") !important;
    background-position: 95% 50%;
    background-color: #ffffff; 
    background-repeat: no-repeat;
    background-size: 1.1rem;
    padding: 0px 0px 0px 16px;
  }

  #selectyears {
    background-color: rgba(196, 196, 196, 0.30);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../assets/img/Vector.svg") !important;
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 1.1rem;
    padding: 0px 0px 0px 16px;
  }

  #selectyears option{
    background-color:white;
    color:#004680;

  }
  
  #selectyears:active
  #selectyears:focus,
  #selectyears:checked {
    color: #004680;
    background-color: #ffff !important;
    border-radius: 10px;
    background-image: url("../../assets/img/Vector2.svg") !important;
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 1.1rem;
    padding: 0px 0px 0px 16px;
  }

  #typeahead_versions {
    background-color: rgba(196, 196, 196, 0.30);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../assets/img/Vector.svg") !important;
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 1.1rem;
    padding: 0px 0px 0px 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 35px;
  }
  #typeahead_versions option{
    background-color:white;
    color:#004680;
  }


  #typeahead_versions:active
  #typeahead_versions:focus,
  #typeahead_versions:checked {
    background-color: #ffff !important;
    border-radius: 10px;
    background-image: url("../../assets/img/Vector2.svg") !important;
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 1.1rem;
    padding: 0px 0px 0px 16px;
    color: #004680;
  }

  #input_km{
    background-color: rgba(196, 196, 196, 0.30);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-size: 1.1rem;
    padding: 0px 0px 0px 16px;
  }
  #input_km:active
  #input_km:focus,
  #input_km:checked {
    color: #004680;
    background-color: #ffff !important;
    border-radius: 10px;
    padding: 0px 0px 0px 16px;
  }
  #input_km::placeholder {
    color: #a5a5a5;
  }

@include media-breakpoint-only(xs) {
  
    /* Estilo cuando el usuario interactúa con la lista de sugerencias */
  .btn-orange{
    margin-top:23px;
    width: 85vw;
  }
  #cards_row_form {
    margin-top: 0px !important;
  }


  #step1 {
    height:  42.5rem;
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }
    .slogan1 {
      font-size: 16px;
    }
    .slogan2 {
      margin-top:1rem;
      font-size: 18px;
    }
  }
}
@include media-breakpoint-only(sm) {
  .btn-orange{
    margin-top:23px;
    width: 60vw;
  }
  .cambio_paso {
    margin: 0;
    padding: 2%;
    border: none;
  }

  #step1 {
    height: 45rem;
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      margin-top:0px;
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }
  }
}

@include media-breakpoint-only(md) {
  .image-icon{
    width:33%;
    border-radius: 50%;
  }
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      margin-top:0%;
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
    }
  }
}

@include media-breakpoint-only(lg) {
  .image-icon{
    width:33%;
    border-radius: 50%;
  }
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;

  }

  #step1 {
    margin-top: 95px;
    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      margin-top: 9%;
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
  }
}

@include media-breakpoint-only(xl) {
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;
    border: 1px #b3dcff solid;
  }
  .image-icon{
    width:33%;
    border-radius: 50%;
  }
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: auto;
    }
    #first_row {
      margin-top: 6%;
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
  }
}
</style>