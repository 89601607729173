<template>
  <div>
    <div id="step1a">
      <div id="first_row" class="row justify-content-center align-items-center">
        <PelotitasPasos :infostep="'Datos del auto'" />
      </div>
      <fieldset :class="{ cambio_paso: transicion }">
        <div class="row justify-content-center pt-2 d-none d-lg-flex hidden-md-and-down">
          <div class="col-10 col-sm-7 col-md-6 col-lg-10 col-xl-10">
            <div class="row justify-content-center">
              <div class="text-center">
                <img src="../../assets/img/mujer.png" class="image-icon" alt="Mujer"/>
              </div>
            </div>
          </div>
        </div>
        <div id="cards_row_form" class="row justify-content-center pt-4">
          <div class="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-6">
            <div class="row justify-content-center">
              <p class="text-center slogan2">¿Tienes un auto?</p>
            </div>
            <div class="text-center">
              <p class="nota1">
                * Recuerda que es necesario que tengas la factura original y la tarjeta de circulación del mismo
              </p>
            </div>
            <div class="row justify-content-center text-center subir">
              <div class="col-12">
                <div class="row justify-content-center">
                  <div class="card-columns">
                    <div class="card" :class="{ active: getFinanciacionDos() }" @click="setFinanciacion('si')">
                      <div class="card-body">
                        <div class="d-flex justify-content-center align-items-center">
                          <span class="font-weight-bold">Sí</span>
                        </div>
                      </div>
                    </div>
                    <div class="card" :class="{ active: getFinanciacion() }" @click="setFinanciacion('no')">
                      <div class="card-body">
                        <div class="d-flex justify-content-center align-items-center">
                          <span class="font-weight-bold">No</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="row justify-content-center" >
      <div class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
        <BotonVolver ></BotonVolver>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import BotonVolver from "./BotonVolver.vue";
import PelotitasPasos from "./PelotitasPasos.vue";

export default {
  name: "Inicio",
  data: function () {
    return {
      transicion: false,
      windowWidth: window.innerWidth,
    };
  },
  store,
  computed: 
  mapState({
    step: (state) => state.formulario.step,
    data_matricula: (state) => state.formulario.data_matricula,
    formulario: (state) => state.formulario,
    afiliado: (state) => state.afiliado,
  }),
  components: { PelotitasPasos, BotonVolver },
  methods: {
    back() {
      this.$router.go(-1);
    },
    
    setFinanciacion(respuesta) {
      this.respuesta = respuesta;
      this.transicion = true;
      setTimeout(() => {
        this.formulario.financiacion = respuesta
        this.$router.push("step2");
      }, 500);
    },

    getFinanciacion: function () {
      return this.formulario.financiacion === "si";
    },
    getFinanciacionDos: function () {
      return this.formulario.financiacion === "no";
    },
    
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },

  watch: {
    windowWidth(value) {
      const menu = document.querySelector('#navigation_menu');
      if (value >= 992) {
        menu.style.display = 'block';
      }else{
        menu.style.display = 'none';
      }
    }
  },
  created: function () {
    window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG

    window.dataLayer.push({
      event: 'auto_pagado',

      idAfiliado: this.afiliado,
    });
  },
  mounted: function () {
    store.commit("setStep", "2");
    //Set color de las bolitas que indican el cambio de paso
    store.commit("setBarraColor1", "blanco");
    store.commit("setCheckColor2", "blanco");
    store.commit("setBarraColor2", "blanco");
    store.commit("setCheckColor3", "blanco");
    const menu = document.querySelector('#navigation_menu');
    if (window.innerWidth >= 992) {
      menu.style.display = 'block';
    }else{
      menu.style.display = 'none';
    }
    window.addEventListener('resize', this.getWindowWidth);
  },
};
</script>

<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.nota1{
  font-family: 'Montserrat-Medium';
  color: #004680;
  font-style: italic;
}

.subir{
  margin-top: 1.8rem;
}
.slogan1{
  font-family: 'Montserrat-Medium';
}

#step1a {

  #image_formulario {
    background-repeat: no-repeat;
    background-size: cover;
  }

  #cards_row_form {
    margin-top: 20px;
  }

  .card {
    background-color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    &:hover {
      background-color: #004680;
      color: white;
      border: 2px solid rgba(196, 196, 196, 0.4);
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      cursor: pointer;
    }
    &:active {
      background-color: #004680;
      color: white;
      border: 2px solid rgba(196, 196, 196, 0.4);
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
    }
  }
  .active {
    background-color: #004680;
    color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}

@include media-breakpoint-only(xs) {
  #navigation_menu {
    display: none;
  }
  #step1a {
    height: 33rem;
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 18px;
      font-family: "Montserrat-Bold";
      margin-top: 2rem;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
  }
}

@include media-breakpoint-only(sm) {
  #step1a {
    height: 40rem;
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 18px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
  }
}

@include media-breakpoint-only(md) {
  #navigation_menu {
    display: none;
  }
  .image-icon{
    width:33%;
    border-radius: 50%;
  }
  #step1a {
    height: 86vh;
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 18px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
  }
}

@include media-breakpoint-only(lg) {
  .image-icon{
    width:33%;
    border-radius: 50%;
  }
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;
    border: 1px #b3dcff solid;
  }
  #step1a{
    margin-top: 110px;
    height: 45rem;
    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 22px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      margin-top:10%;
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
  }
}

@include media-breakpoint-only(xl) {
  .image-icon{
    width:33%;
    border-radius: 50%;
  }
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;
    border: 1px #b3dcff solid;
  }
  #step1a {
    margin-top: 110px;
    height: 45rem;
    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    .slogan2 {
      font-size: 22px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 525px;
    }
    #first_row {
      margin-top: 10%;
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
    .card:hover {
      width: 100%;
      height: auto;
      span {
        font-size: 28px;
      }
    }
  }
}
</style>
