<template>
  <div id="stepDoc">
    <section id="documentacion_subida" class="container pb-2">
      <div class="row justify-content-center align-items-center">
        <template v-for="file in files" v-bind:key="file.id">
            <div class="" style="position: relative; margin: 12px;">
              <div v-if="comprobarExtension('https://ibancar-production-documentos.s3.eu-west-1.amazonaws.com/documentos/clientes/' + solicitud.idclientes + '/' + file.ruta)" class="" >
                <img width="65" height="55" :src="'https://ibancar-production-documentos.s3.eu-west-1.amazonaws.com/documentos/clientes/' + solicitud.idclientes + '/' + file.ruta">
                <img width="22" height="22" @click="openRemoveModal(file)" src="@/assets/img/dropzone_remove.svg" alt="remove file" class="iconRemove" />
              </div>
              <div v-else class="" >
                <img width="65" height="55" :src="'/file-pdf-solid.svg'" >
                <img width="22" height="22" @click="openRemoveModal(file)" src="@/assets/img/dropzone_remove.svg" alt="remove file" class="iconRemove" />
              </div>
            </div>
        </template>
      </div>
    </section>
    <section class="container-fluid pt-4">
      <div class="row">
        <div class="col-12 text-center">
          <form id="dropzone" :action="publicPath + 'createDocumentacion'" method="POST" class="dropzone">
            <div class="dz-message" data-dz-message>
              <img width="auto" height="58" src="@/assets/img/image_cloud_black.svg" alt="Cloud upload" /><br />
              <span class="">Arrastra aquí o selecciona los archivos</span>
            </div>
            <div class="fallback">
              <input name="file" type="file" multiple accept="image/png, image/pdf, image/jpeg, image/jpg, audio/mp3, .mp3, video/mp4, .mp4" />
            </div>
            <input type="hidden" name="token" :value="api_token" />
            <input type="hidden" name="idCliente" :value="solicitud.idclientes" />
            <input type="hidden" name="idSolicitud" :value="solicitud.id" />
            <input type="hidden" name="procedencia" value="web" />
          </form>
        </div>
      </div>
      <br />
      <div class="d-flex row justify-content-center align-items-center pt-4">
        <button :disabled="files.length < 2" @click="finalizarSolicitud()" class="btn-orange center p-2 fs-17">He finalizado mi solicitud</button>
      </div>
      <div class="d-flex row justify-content-center align-items-center pt-3">
        <p class="info ml-4 mr-4">Si no puedes finalizar ahora, los documentos subidos permanecen guardados</p>
      </div>
      <br />
    </section>


    <!-- Modal confirmar borrado archivo -->
    <button id="btnModal" class="d-none" data-toggle="modal" data-target="#modalConfirmDelete"></button>
    <div id="modalConfirmDelete" class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="d-flex row justify-content-center align-items-center m-3">
            <div class="col-12">
              <p class="center text-center fs-18">¿Eliminar esta imagen?</p>
            </div>
            <div class="col-6 d-flex row justify-content-center align-items-center">
              <button class="btn btn-cancelar" data-dismiss="modal">Cancelar</button>
            </div>
            <div class="col-6 d-flex row justify-content-center align-items-center">
              <button @click="deleteFile()" class="btn btn-eliminar" data-dismiss="modal">Eliminar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import { mapState } from "vuex";
import Dropzone from "dropzone";
Dropzone.autoDiscover = false;

export default {
  name: "NuevoStepDoc",
  data: function () {
    return {
      publicPath: process.env.VUE_APP_AXIOS_BASE_URL,
      dropzone: undefined,
      file: undefined,
      files: [],
    };
  },
  store,
  computed: {
    ...mapState({
      step: (state) => state.formulario.step,
      api_token: (state) => state.api_token,
      formulario: (state) => state.formulario,
      data_matricula: (state) => state.formulario.data_matricula,
      solicitud: (state) => state.solicitud,
      max_prestamo: (state) => state.max_prestamo,
    }),
  },
  methods: { 
    goTo(route, hash) {
      this.$router.push({ path: route, hash: hash });
    },
    getDocuments: function () {
      window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG

      window.dataLayer.push({
        event: "subir_documentacion_web",
      });
      axios.post(process.env.VUE_APP_AXIOS_BASE_URL + "getDocumentacion/solicitud/" + this.solicitud.id).then((resp) => {
        this.files = resp.data.data;
      });
    },
    initDropzone: function () {
      this.dropzone = new Dropzone("#dropzone", {
        // headers: {'x-csrf-token': CSRF_TOKEN,},
        parallelUploads: 2,
        thumbnailHeight: 120,
        thumbnailWidth: 120,
        filesizeBase: 1000,
        success: (file) => {
          this.getDocuments();
          file.previewElement.parentNode.removeChild(file.previewElement);
          var elemento = document.getElementsByClassName("dz-message");
          elemento ? (elemento[0].style.display = "block") : false;
        },
        dictRemoveFile: '<svg style="position: absolute;top: -10px;right: -10px;z-index: 100;" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 0C17.083 0 22 4.917 22 11C22 17.083 17.083 22 11 22C4.917 22 0 17.083 0 11C0 4.917 4.917 0 11 0ZM14.949 5.5L11 9.449L7.051 5.5L5.5 7.051L9.449 11L5.5 14.949L7.051 16.5L11 12.551L14.949 16.5L16.5 14.949L12.551 11L16.5 7.051L14.949 5.5Z" fill="#333333"/></svg>',
        addRemoveLinks: true,
        removedfile: (file) => {
          this.openRemoveModal(file);
          // Quitar mensaje cuando aun quedan archivos
          // setTimeout(() => {
          //     this.dropzone.getAcceptedFiles().length > 0 ? document.getElementById('dropzone').classList.add("dz-started") : '';
          // }, 10);
          return false;
        },
        createImageThumbnails: false,
      });
    },
    openRemoveModal: function (file) {
      this.file = file.id.data ? file.id.data : file;
      document.getElementById("btnModal").click();
    },
    deleteFile: function () {
      axios.post(process.env.VUE_APP_AXIOS_BASE_URL + "deleteDocumentacion/" + this.file.id).then((resp) => {
        if (resp.data) {
          // Quitamos del listado
          for (let i = 0; i < this.files.length; i++) {
            const file = this.files[i];
            if (file.id == this.file.id) {
              // Quitar de array
              this.files.splice(i, 1);
              // Quitar de preview
            }
          }
          // Quitamos del dropzone
          var _ref;
          return (_ref = this.file.previewElement) != null ? _ref.parentNode.removeChild(this.file.previewElement) : void 0;
        }
      });
    },
    finalizarSolicitud: function () {
      axios.post(process.env.VUE_APP_AXIOS_BASE_URL + "update/formulario/solicitud/finalizar/" + this.solicitud.id).then(() => {
        // this.$parent.setStep(9);
        store.commit("setStep", 9);
        this.goTo("/formulario/confirmacion", "#newStepOk");
      });
    },
    comprobarExtension(url) {
      var ext = url.split(".").reverse()[0];
      if (ext == "pdf" || ext == "doc") {
        return false;
      }
      return true;
    },
  },
  watch: {},
  mounted: function () {
    this.initDropzone();
    this.getDocuments();
  },
};
</script>

<style scoped lang="scss">
.bg-img {
  height: 165px;
  width: 95%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 10px;
  -webkit-box-shadow: 4px 2px 5px -5px rgba(0, 0, 0, 0.92);
  box-shadow: 4px 2px 5px -5px rgba(0, 0, 0, 0.92);
}

@import "@/assets/styles/variables.scss";
#stepDoc {
  height: auto;
  // border-radius: 7px;
  // box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  // background-color: #f2f9ff;
  #dropzone {
    width: 60vw;
    height: auto;
    background-color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    border-image: none;
    margin-left: auto;
    margin-right: auto;
    span {
      font-size: 14px;
      font-weight: 600;
      color: $blue;
    }
  }
  .btn-cancelar {
    border: 1px solid $orange;
    box-sizing: border-box;
    color: $orange;
  }
  .btn-eliminar {
    background: $orange;
    color: $white;
  }
  .btn-finalizar {
    background: $orange;
    color: $white;
    overflow: hidden;
    white-space: nowrap;
    font-size: 17px;
    font-weight: 700;
  }
  .fs-18 {
    font-size: 18px;
  }
  .iconRemove {
    position: absolute;
    top: -13px;
    right: -4px;
    border-radius: 50%;
    -webkit-box-shadow: 4px 2px 5px -5px rgba(0, 0, 0, 0.92);
    box-shadow: 4px 2px 5px -5px rgba(0, 0, 0, 0.92);
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .container_input_dni {
    margin-top: 1rem !important;
  }
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-between(xs, sm) {
  .title-1 {
    font-size: 1.3rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .container {
    max-width: 100%;
  }

  #dropzone {
    width: 100% !important;
  }
  .btn-finalizar {
    font-size: 17px !important;
  }
  .info {
    font-size: 14px;
  }
}

@include media-breakpoint-between(md, lg) {
  .container {
    width: 650px;
  }
}

@include media-breakpoint-between(xl, xxl) {
  .container {
    width: 650px;
  }
  .dropzone {
    max-width: 675px !important;
  }
}
</style>
