<template>
    <div id="formulario" class="center">
        <div class="d-flex flex-row justify-content-center align-items-center pb-2">
            <span class="font-weight-bold" style="color:#003159; font-size: 1rem; font-family: 'Montserrat-Bold';">{{this.infostep}}</span>
        </div>
        <div class="">
            <div id="pelotitas" class="d-flex flex-row justify-content-center align-items-center">
                <div >
                    <img width="20" height="20" :src="publicPath+'check-'+checkcolor1+'-1.svg'" alt="check1">
                    <img width="30" height="15" :src="publicPath+'barra-'+barracolor1+'.svg'" alt="check1">
                    <img width="20" height="20" :src="publicPath+'check-'+checkcolor2+'.svg'" alt="check2">
                    <img width="30" height="15" :src="publicPath+'barra-'+barracolor2+'.svg'" alt="check1">
                    <img width="20" height="20" :src="publicPath+'check-'+checkcolor3+'.svg'" alt="check3">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'
// import Loader from '@/components/Loader.vue'

export default {
    name: 'formulario',
    data(){
        return {
            publicPath: process.env.BASE_URL
        }
    },
    props: {
        infostep: String
    },
    store,
    computed: mapState({
        formulario: state => state.formulario,
        checkcolor1: state => state.checkcolor1,
        checkcolor2: state => state.checkcolor2,
        checkcolor3: state => state.checkcolor3,
        barracolor1: state => state.barracolor1,
        barracolor2: state => state.barracolor2
    }),
    components:{
        // Loader,
    },
    methods: {

    },
    mounted(){
    }
}
</script>

<style lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

    // #formulario{
    //     width: 530px;
    //     height: auto;
    //     min-height: 600px;
    //     padding: 20px 30px;
    //     background-color: transparent;

    //     // 
    //     display: flex!important;
    //     justify-content: space-around;
    //     align-items: center;
    //     flex-direction: column;


        .loader{
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .text-loader{
                text-align: center;
                margin-bottom: 14px;
                font-size: 19px;
            }
        }

    //     .button{
    //         padding: 12px 30px 12px 35px;
    //         border-radius: 30px;
    //         background-color: $orange;
    //         font-size:20px;
    //         color:white;
    //         font-weight:bold;
    //         cursor:pointer;
    //         margin-top:10px;
    //         display:inline-block;
    //         border: 0;

    //         &[disabled]{
    //             background-color: #c4c4c4;
    //             cursor: not-allowed;
    //         }
    //     }

    //     .label{
    //         text-align: left;
    //         display: block;
    //         margin: 0;
    //         line-height: 30px;
    //         color: #333;
    //         font-size: 16px;
    //     }
    //     #pelotitas {
    //         opacity: 1;
    //     }
    //     #mensaje-recopilando {
    //         color: #333;
    //     }
    // }

    @include media-breakpoint-between(xs, xl) {
        #formulario{
            width: auto;
            min-height: 10px;
            min-width: 350px;
            // padding: 5px 15px;
            height: auto;
            border-radius: 0px;
        }
        // #pelotitas {
        //     // padding: 15px;
        // }
    }


</style>
