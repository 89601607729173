<template>
    <div id="volver_row_form" >
        <p class="text-left">
            <a class="volver_circle" href="#" @click="back()" >
                <img src="../../assets/img//mexlanding/Arrow1.png" alt="Flecha Atras"/>
            </a>
        </p>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter();
const back = () => router.go(-1); 

</script>

<style lang="scss" scoped>
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
    .volver_circle {
      height: 35px;
      width: 35px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      border: solid 1px #DADADA;
      border-radius: 50%; /* may require vendor prefixes */
      background: #fff;
    }


</style>