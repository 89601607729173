<template>
  <div id="emailResponse">
    <ul class="nodots">
      <li class="pb-1">
        <div class="row justify-content-center text-center">
          <div class="card-columns pt-0 pl-4 pr-4 pb-4">
            <div class="card pe-clickable" @click="openWhatsapp()">
              <div class="row align-items-center">
                <div class="col-3 pt-3 pb-3">
                  <img src="../../assets/img/whatsapp.svg" alt="dinero" />
                </div>
                <div class="col-9 text-left">
                  <span class="font-weight-bold text-dark" >Enviar documentación por WhatsApp</span>
                </div>
              </div>
            </div>
            <div v-if="step==5" class="card pe-clickable" @click="setStep(6)">
              <div class="row align-items-center">
                <div class="col-3 pt-3 pb-3">
                  <img src="../../assets/img/documentacion.svg" alt="documentacion" />
                </div>
                <div class="col-9 text-left">
                  <span class="font-weight-bold text-dark">Adjuntar documentación <br>en la web</span>
                </div>
              </div>
            </div>
            <div class="card pe-clickable" @click='openLlamada()'>
              <a href="tel:5555661583">
                <div class="row align-items-center">
                  <div class="col-3 pt-3 pb-3">
                    <img src="../../assets/img/phone.svg" alt="phone" />
                  </div>
                  <div class="col-9 text-left">
                    <span class="font-weight-bold text-dark" >Llamar por teléfono</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </li>

      <li>
        <!-- <div id="btn-container" class="row justify-content-center">
          <div class="col-12 col-md-6 col-xl-4 offset-xl-2 justify-content-center " id="reiniciar-solicitud">
            <img  width="19" height="12" src="./../../assets/img/bluearrowleft.svg" alt="ibancar">
            <a @click="openModalConfirmar()">
              <u><b>Este no es mi auto</b></u>
            </a> 
          </div>
        </div> -->
        <br>
        <div id="btn-container" class="row justify-content-center">
          <div class="col-12 col-md-6 col-xl-4 offset-xl-2 justify-content-center " id="reiniciar-solicitud">
            <img  width="19" height="12" src="./../../assets/img/bluearrowleft.svg" alt="ibancar">
            <a @click="openModalConfirmarReinicio()">
              <u style="font-weight: 900; cursor: pointer;"><b>Reiniciar solicitud</b></u>
            </a> 
          </div>
        </div>
        <!-- Modal confirmar reinicio solicitud -->
        <button id="btn_modal_reiniciar_solicitud" class="d-none" data-toggle="modal" data-target="#modalReiniciarSolicitud"></button>
        <div id="modalReiniciarSolicitud" class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
          <div id="modal-reiniciar" class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="d-flex row justify-content-center align-items-center m-3">
                <div class="col-12">
                  <p class="center text-center fs-18">¿Estás seguro de querer eliminar tu solicitud de préstamo?</p>
                </div>
                <div class="col-6 d-flex row justify-content-center align-items-center">
                  <button class="btn btn-cancelar" data-dismiss="modal">No, reiniciar</button>
                </div>
                <div class="col-6 d-flex row justify-content-center align-items-center">
                  <button @click="reiniciarSolicitud()" class="btn btn-eliminar" data-dismiss="modal">Reiniciar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End modal -->

        <!-- Modal confirmar reinicio solicitud -->
        <button id="btn_modal_reiniciar" class="d-none" data-toggle="modal" data-target="#modalReiniciar"></button>
        <div id="modalReiniciar" class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
          <div id="modal-reiniciar" class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="d-flex row justify-content-center align-items-center m-3">
                <div class="col-12">
                  <p class="center text-center fs-18">¿Quieres reiniciar tu solicitud</p>
                </div>
                <div class="col-6 d-flex row justify-content-center align-items-center">
                  <button class="btn btn-cancelar" data-dismiss="modal">No, reiniciar</button>
                </div>
                <div class="col-6 d-flex row justify-content-center align-items-center">
                  <button @click="reiniciarSolicitud()" class="btn btn-eliminar" data-dismiss="modal">Reiniciar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End modal -->
      </li>
    </ul>
  </div>
</template>

<script >
//import axios from "axios";
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "EmailRespuesta",
  data: function () {
    return {
      horario: false,
    };
  },
  store,
  computed: mapState({
    step: (state) => state.formulario.step,
    api_token: (state) => state.api_token,
    formulario: (state) => state.formulario,
    token_enlace: (state) => state.token_enlace,
    data_matricula: (state) => state.formulario.data_matricula,
    solicitud: (state) => state.solicitud,
  }),
  methods: {
    goTo(route, hash) {
      this.$router.push({ path: route, hash: hash });
    },
    openWhatsapp: function () {
      window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG

      window.dataLayer.push({
        event: "click_whatsapp",
      });
      window.open("https://api.whatsapp.com/send?l=es&phone=5215612673878&text=%F0%9F%91%8B%20%C2%A1Hola!%20Prefiero%20continuar%20mi%20solicitud%20de%20pr%C3%A9stamo%20por%20WhatsApp.%20Muchas%20gracias.", "_blank");
    },
    setStep(value) {
      this.$nextTick(function () {
        store.commit("setStep", value);
      });
      if (value == 6) {
        this.$router.push("confirmacion", "#documentacionNecesaria");
        //this.$router.push("documentacion", "#documentacionNecesaria");
      }
    },
    openModalConfirmarReinicio() {
      document.getElementById("btn_modal_reiniciar_solicitud").click();
    },
    openModalConfirmar() {
      document.getElementById("btn_modal_reiniciar").click();
    },
    reiniciarSolicitud() {
      // Buscar id solicitud
      store.commit("resetSolicitud");
      this.$router.push({ path: "/", hash: null });
      /*
      var idSolicitud = this.solicitud.id;
      axios.post(process.env.VUE_APP_AXIOS_BASE_URL + "mexico/reiniciar/solicitud/" + idSolicitud).then((resp) => {
        let bool = resp.data;
        if (bool == true) {
          store.commit("resetSolicitud");
          this.$router.push({ path: "/", hash: null });
        }
      });
    },
        openLlamada: function () {
      window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG

      window.dataLayer.push({
        event: "click_telefono",
      });*/
    },

    checkHorario: function () {
      var response = false;
      var date = new Date();
      // Sacamos la hora del día en la que estamos
      var hour = date.toLocaleString("es-ES", { hour: "2-digit", hour12: false, timeZone: "Europe/Madrid" }) + ":" + (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

      // Sacamos día de la semana
      var day_name = date.getDay();
      switch (day_name) {
        case 1: // lunes
        case 2: // martes
        case 3: // miércoles
        case 4: // jueves
        case 5: // viernes
          if (hour >= "09:00" && hour <= "20:00") {
            response = true;
          }
          break;
        case 6: // sábado
          if (hour >= "10:00" && hour <= "14:00") {
            response = true;
          }
          break;
        default:
          break;
      }
      this.horario = response;
    },
  },
  mounted: function () {
    this.checkHorario();
    // Redirigir segun el paso
    var stepGet = this.$route.query.step;
    if (stepGet != undefined) {
      var step = 5;
      switch (stepGet) {
        case "doc":
          step = 6;
          break;
        case "call":
          step = 7;
          break;

        default:
          break;
      }
      this.setStep(step);
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
.btn-cancelar {
  border: 1px solid $orange;
  box-sizing: border-box;
  color: $orange;
}
.btn-eliminar {
  background: $orange;
  color: $white;
}
.nodots {
  list-style-type: none;
  padding: 0;
}

.card {
  background-color: white;
  border: 2px solid rgba(196, 196, 196, 0.4);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

#reiniciar-solicitud{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #004680;
  text-align: center;
  margin-left: -0.5rem;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-only(xs) {
  h2 {
    font-size: 1.25rem;
  }
  .card-columns {
    column-count: 1;
    max-width: 100%;
    width: 100%;
  }
  .card {
    width: 100%;
    height: auto;
  }
  .card:hover {
    width: 100%;
    height: auto;
  }
}

@include media-breakpoint-only(sm) {
  h2 {
    font-size: 1.25rem;
  }
  .card-columns {
    column-count: 1;
    max-width: 100%;
    width: 80%;
  }
  .card {
    width: 100%;
    height: auto;
  }
  .card:hover {
    width: 100%;
    height: auto;
  }
}

@include media-breakpoint-between(md, lg) {
  h2 {
    font-size: 1.8rem;
  }

  .card-columns {
    column-count: 1;
    max-width: 100%;
    width: 70%;
  }

  .fs-18 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@include media-breakpoint-between(xl, xxl) {
  .fs-18 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .card-columns {
    column-count: 3;
    max-width: 200%;
    width: 200%;
  }
}
</style>
