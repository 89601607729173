<template>
    <div id="newStepOk">
      <div class="row">
        <div class="col-12">
          <div class="column justify-content-center align-items-center bg">
            <h3 class="font-weight-bold text-center text-white">¡Felicidades!</h3>
            <h6 class="font-weight-bold text-center text-white">Tienes un préstamo<br/> preaprobado de</h6>
          </div>
          <h3 class="font-weight-bold text-center valor">
            <svg style="position:absolute; top:15.3rem; left: 0px;" xmlns="http://www.w3.org/2000/svg" width="52" height="55" viewBox="0 0 52 55" fill="none">
              <path d="M-17.585 0.191209L51.3395 53.9668C37.1286 54.8957 -5.82882 56.0786 -12.2026 52.1946C-20.9195 46.8828 -19.2952 11.0907 -17.585 0.191209Z" fill="#FFA500"/>
            </svg>
            <svg style="position:absolute; top:13.7rem; right: 0px;" xmlns="http://www.w3.org/2000/svg" width="66" height="49" viewBox="0 0 66 49" fill="none">
            <path d="M90.2976 49L0.152344 0.749352C18.7824 -0.04581 75.0921 -0.996682 83.4322 2.49174C94.8383 7.26259 92.5789 39.2593 90.2976 49Z" fill="#FFA500"/>
            </svg>
           ${{ maxPrestar}}
          </h3>
          <div class="row justify-content-center mt-3">
            <div class="text-center" style="font-size: 1rem; font-weight: 700; font-family: 'Montserrat-Bold'">Con tu coche</div>
          </div>
          <div class="row justify-content-center">
            <div class="text-center"  style="font-size: 0.7rem; font-family: 'Montserrat'"> (Sujeto a estudio crediticio)</div>
          </div>
          <div class="row justify-content-center pt-5 pb-3">
            <div class="container">
              <div class="col-12">
                <p class="text-blue text-center font-weight-bold">Consigue tu préstamo hoy.</p>
              </div>
            </div>
          </div>
          <NuevoEmailRespuesta  />
          <Documentacion ></Documentacion>
        </div>
      </div>
      <BotonVolver />
    </div>
  </template>
  
  <script>
  import store from "@/store";
  import { mapState } from "vuex";
  import NuevoEmailRespuesta from "./NuevoEmailRespuesta.vue";
  import Documentacion from "./Documentacion.vue";
  import BotonVolver from "./BotonVolver.vue";
  export default {
    name: "pre-aproval",
    data: function () {
      return {
        editar: false,
        telefono: "",
        email: "",
        checkTelefono: false,
        checkEmail: false,
      };
    },
    components: { NuevoEmailRespuesta,Documentacion , BotonVolver },
    props: {
      solicitud: Object,
    },
    methods: {
      setContacto: function () {
        this.telefono = this.solicitud ? this.solicitud.def_telefono1 : null;
        this.email = this.solicitud ? this.solicitud.def_email1 : null;
      },
      validatePhone(phone) {
        let pattern = /^\d{9}$/;
        if (pattern.test(phone)) {
          return true;
        }
        return false;
      },
      validateEmail(mail) {
        var pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (pattern.test(mail)) {
          return true;
        } else if (mail == "") {
          return false;
        }
        return false;
  
      },
  
      openWhatsapp: function () {
        window.open("https://api.whatsapp.com/send?l=es&phone=5215612673878&text=%F0%9F%91%8B%20%C2%A1Hola!%20Prefiero%20continuar%20mi%20solicitud%20de%20pr%C3%A9stamo%20por%20WhatsApp.%20Muchas%20gracias.", "_blank");
      },
      back() {
        store.commit("setStep", 6);
        this.$router.go(-1);
      },
    },
    watch: {
      telefono: function () {
        this.checkTelefono = this.validatePhone(this.telefono);
      },
      email: function () {
        this.checkEmail = this.validateEmail(this.email);
      },
    },
    store,
    computed: mapState({
      step: (state) => state.formulario.step,
      api_token: (state) => state.api_token,
      formulario: (state) => state.formulario,
      data_matricula: (state) => state.formulario.data_matricula,
      solicitud: (state) => state.solicitud,
      max_prestamo: (state) => state.max_prestamo,
    }),
    mounted: function () {
      this.setContacto();
    },
  };
  </script>
  
  <style scoped lang="scss">
  // Resposive para pantallas a partir de tamaño medio a pequeño
  
  @import "bootstrap/scss/_functions.scss";
  @import "bootstrap/scss/_variables.scss";
  @import "bootstrap/scss/_mixins.scss";
  
  #newStepOk {
    .card {
      background-color: white;
      border: 2px solid rgba(196, 196, 196, 0.4);
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
    }
    .text-blue {
      color: #004680;
    }
    .volver_circle {
      height: 35px;
      width: 35px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      border: solid 1px #dadada;
      border-radius: 50%; /* may require vendor prefixes */
      background: #fff;
    }
  }
  
  @include media-breakpoint-only(xs) {
    .valor {
      width: 100%;
      height: 5rem;
      flex-shrink: 0;
      background-color:rgb(255,246,230)!important;
      margin-top:-1rem;
      padding-top:1rem;
      color: var(--ibancar-blue-004680, #004680);
      font-family: Montserrat;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .bg {
      padding-top: 1.5rem;
      height: 9rem !important;
      
    }
    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
  
  @include media-breakpoint-only(sm) {
    .bg {
      height: 8rem;
      padding: 1rem;
      margin-top: 106px !important;
      margin-bottom: 25px;
    }
    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
  
  @include media-breakpoint-only(md) {
    .bg {
      height: 120px;
      padding: 1rem;
      margin-top: 90px !important;
      margin-bottom: 25px;
    }
    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
  
  @include media-breakpoint-only(lg) {
    .bg {
      height: 120px;
      padding: 1rem;
      margin-top: 95px !important;
      margin-bottom: 25px;
    }
    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      max-height: 78px;
    }
    .card:hover {
      width: 100%;
      height: auto;
      max-height: 78px;
    }
  }
  
  @include media-breakpoint-between(xl, xxl) {
    .bg {
      height:  8rem;
      padding: 1rem;
      margin-top: 95px !important;
      margin-bottom: 25px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
      max-height: 78px;
    }
    .card:hover {
      width: 100%;
      height: auto;
      max-height: 78px;
    }
  }
  </style>
  