<template>
  <div id="step3">
    <div class="loader" v-if="!preload2" style="background-color:#013159;">
        <LoaderMexico/>
    </div>
    <fieldset v-else>
      <div id="first_row" class="row justify-content-center align-items-center">
      <PelotitasPasos :infostep="'Datos del titular'" />
    </div>
      <div id="fourth_row_form" class="row justify-content-center pt-2 d-none d-md-flex hidden-md-and-down">
        <div class="col-10 col-sm-7 col-md-11 col-lg-10 col-xl-10">
          <div class="row justify-content-center">
            <p class="text-center slogan1">Esta propuesta de préstamo es gratis y sin compromiso</p>
          </div>
        </div>
      </div>
      <div id="cards_row_form" class="row justify-content-center pt-3">
        <div class="col-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 mt-2">
          <div class="row justify-content-center">
            <p class="text-center" style="font-size: 20px; font-weight: 700; font-family: 'Montserrat-Bold'">Datos personales</p>
          </div>
          <div class="row justify-content-center mt-1">
            <div class="col-12 col-md-6 col-lg-6 p-2">
              <input type="text" :class="{ error: errors.nombre }" name="nombre" id="nombre" class="ibn_input nombre" placeholder="Nombre" v-model="formulario.nombre" required />
              <p v-if="errors.nombre && formulario.nombre.length == 0" class="error">Debes rellenar este campo...</p>
              <p v-if="errors.nombre && formulario.nombre.length > 0 && formulario.nombre.length <= 2" class="error">Debe tener mínimo 3 carácteres</p>
            </div>
            <div class="col-12 col-md-6 p-2 autocompletar">
              <input id="codigo_postal" type="text" :class="{ error: errors.codigo_postal }" name="codigo_postal" class="ibn_input" placeholder="Código postal" max="99999" maxlength="5" v-model="formulario.codigo_postal" />
              <p v-if="errors.codigo_postal" class="error">El código postal no es válido...</p>
            </div>
            <div class="col-12 col-md-6 p-2">
              <input type="tel" :class="{ error: errors.telefono }" min="0" max="999999999" name="telefono" id="telefono" class="ibn_input" placeholder="Teléfono" maxlength="10" v-model="formulario.telefono" />
              <p v-if="errors.telefono" class="error">Debe ser un teléfono válido...</p>
            </div>

            <div class="col-12 col-md-6 p-2">
              <input type="text" :class="{ error: errors.email }" name="email" id="email" class="ibn_input" placeholder="ejemplo@gmail.com" v-model="formulario.email" required />
              <p v-if="errors.email" class="error">Debe ser un email válido...</p>
            </div>
            <!--
            <div class="col-12 pt-4">
              <label class="checkbox_label" for="aceptoAll">
                <input type="checkbox" id="aceptoAll" class="checkbox" name="legal_advise" @click="marcarCheckBoxs($event)" required />
                <span class="checkbox_text pl-2 font-weight-bold">Seleccionar / deseleccionar todo</span>
                <p v-if="errors.check1 || errors.check2" class="error">Debes marcar los campos restantes para avanzar...</p>
              </label>
            </div>
            -->
            <div class="col-12 pl-xl-3">

              <div class="d-flex">
                <div class="col-1 pt-4 text-center">
                  <input type="checkbox" id="acepto1" class="checkbox" name="legal_advise" v-model="formulario.acepto1" @click="marcarCheckBoxs($event)" required/>
                </div>
                <div class="col-11 pt-4 pl-2">
                  <label class="checkbox_label" for="acepto1">
                    <span class="checkbox_text">He leído el y acepto el <strong><router-link to="/politica-privacidad" target="_blank">Aviso de Privacidad</router-link></strong>.</span>
                  </label>
                  <p v-if="errors.check1" class="error">Debe marcar este campo...</p>
                </div>
              </div>
  
              <div class="d-flex">
                <div class="col-1 pt-4 text-center">
                  <input type="checkbox" id="acepto2" class="checkbox" name="legal_advise" v-model="formulario.acepto2" @click="marcarCheckBoxs($event)" />
                </div>
                <div class="col-11 pt-4 pl-2">
                  <label class="checkbox_label" for="acepto2">
                    <span class="checkbox_text">Acepto recibir información de <strong>IBANCAR LATAM S.A. de C.V. </strong><span v-if="leerMas">que, según lo indicado en el <router-link to="/politica-privacidad" target="_blank">Aviso de Privacidad</router-link>, consideren pertinente enviarme por correo electrónico y por WhatsApp. (Existe la opción de darme de baja en cualquier momento) </span></span>
                  </label>
                  <small @click="leerMas = !leerMas" class="verMas pb-1" :class="leerMas ? 'verMenos' : ''">{{ leerMas ? "Leer menos" : "Leer mas" }}</small>
                  <p v-if="errors.check2" class="error">Debe marcar este campo...</p>
                </div>
              </div>
            </div>

            <div class="col-12 text-center pt-5 ">
              <button type="button" class="btn-orange" @click="comprobarDatos()" :disabled="!solicitar" >Solicitar préstamo</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center" >
        <div class="col-11 col-md-11 pt-5">
          <BotonVolver ></BotonVolver>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import { mapState } from "vuex";
import PelotitasPasos from "./PelotitasPasos.vue";
import LoaderMexico from '@/components/FormularioMexico/Loading.vue'
import BotonVolver from "./BotonVolver.vue";
window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG

export default {
  name: "Step3",
  data: function () {
    return {
      solicitar: true,
      transicion: false,
      windowWidth: window.innerWidth,
      preload2: true,
      publicPath: process.env.BASE_URL,
      leerMas: false,
      provincias: [],
      info:[],
      errors: {
        nombre: false,
        dni: false,
        email: false,
        telefono: false,
        check1: false,
        check2: false,
        codigo_postal: false,
      },
    };
  },
  created() {
    window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG
    window.dataLayer.push({
      event: "datos_personales",
      idAfiliado: this.afiliado,
    });
  },
  store,
  computed: {
    ...mapState({
      step: (state) => state.formulario.step,
      data_matricula: (state) => state.formulario.data_matricula,
      formulario: (state) => state.formulario,
      preload: (state) => state.preload,
      afiliado: (state) => state.afiliado,
    }),
  },
  components: {
    PelotitasPasos,
    BotonVolver,
    LoaderMexico
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    marcarCheckBoxs(event) { 
      //LOGICA DE CHECKS PARA QUE SE SELECCIONE/DESELECCIONE TODO AL PULSAR EL CHECK CORRESPONDIENTE
      const checkbox_name = event.target.id;
      let acepto1 = document.getElementById("acepto1");
      let acepto2 = document.getElementById("acepto2");
      switch (checkbox_name) {
        case "acepto1":
          if (acepto1.checked & acepto2.checked) {
          
            store.commit("setCheckAcepto1", true);
          } else {
            
            store.commit("setCheckAcepto1", false);
          }
          break;
        case "acepto2":
          if (acepto1.checked & acepto2.checked) {
           
            store.commit("setCheckAcepto2", true);
          } else {
           
            store.commit("setCheckAcepto2", false);
          }
          break;
      }
    },
    
    limitLengthCP(){
      const length = this.formulario.codigo_postal.toString().length;
      if (length > 4) {
        this.formulario.codigo_postal = this.formulario.codigo_postal.toString().slice(0,5); 
      }
    },
    comprobarNombre() {
      if (this.formulario.nombre == "") {
        document.getElementById("nombre").style.border = "2px solid red";
        this.errors.nombre = true;
        return false;
      } 
      else if (this.formulario.nombre != "" && this.formulario.nombre.length <= 2) {

        document.getElementById("nombre").style.border = "2px solid red";
        
        this.errors.nombre = true;
        return false;
      } 
      else {
        this.errors.nombre = false;
        document.getElementById("nombre").style.backgroundColor ="#ffffff !important";
        document.getElementById("nombre").style.border = "2px solid #004680";
        document.getElementById("nombre").style.color = "#004680 !important";
        return true;
      }
    },
    comprobarCP() {
      const length = this.formulario.codigo_postal.toString().length;
      if (length == 0 || (length != 4 && length != 5)) {
        this.errors.codigo_postal = true;
        document.getElementById("codigo_postal").style.border = "2px solid red";
       
        return false;
      } else {
        this.errors.codigo_postal = false;
        document.getElementById("codigo_postal").style.backgroundColor  = "#ffffff !important";
        document.getElementById("codigo_postal").style.border = "2px solid #004680";
        document.getElementById("codigo_postal").style.color = "#004680";
        document.getElementById("codigo_postal").style.backgroundColor ="#ffffff !important";
        
        return true;
      }
    },
    comprobarEmail() {
      if (!this.validateEmail(this.formulario.email)) {
        document.getElementById("email").style.border = "2px solid #DC143C";
        this.errors.email = true;
        return false;
      } else {
        this.errors.email = false;
        document.getElementById("email").style.border = "2px solid #004680";
        document.getElementById("email").style.color = "#004680";
        document.getElementById("email").style.backgroundColor ="#ffffff !important";
        
        return true;
      }
    },
    validateEmail(mail) {
      var pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (pattern.test(mail)) {
        return true;
      } else if (mail == "") {
        return false;
      }
      return false;
    },
    comprobarTelefono() {
      if (!this.validatePhone(this.formulario.telefono)) {
        this.errors.telefono = true;
        return false;
      } else {
        this.errors.telefono = false;
        document.getElementById("telefono").style.border = "2px solid #004680";
        document.getElementById("telefono").style.color = "#004680";
        document.getElementById("telefono").style.backgroundColor ="#ffffff !important";
        
        return true;
      }
    },
    validatePhone(phone) {
      let pattern = /^\d{10}$/;
      if (pattern.test(phone)) {
        return true;
      }
      return false;
    },
    comprobarChecks() {
      this.errors.check1 = this.formulario.acepto1 ? false : true;
      this.errors.check2 = this.formulario.acepto2 ? false : true;
      return !this.errors.check1 && !this.errors.check2;
    },

    comprobarDatos() {
    //comprobarDatos() {
      const nombre = this.comprobarNombre();
      const codigo_postal = this.comprobarCP();
      const telefono = this.comprobarTelefono();
      const email = this.comprobarEmail();
      const checks = this.comprobarChecks();

      if (telefono && email  && nombre && checks && codigo_postal) {
        this.solicitar = false;
        this.preload2 = false;
        this.registrarSolicitud();
      }
    },
    
    getYear(fecha) {
      let date = new Date(fecha);
      return date.getFullYear();
    },

    async registrarSolicitud() {
      window.scrollTo(0, document.getElementById("formulario").scrollTop);
      let url_origen = localStorage.getItem("url_origen") ? localStorage.getItem("url_origen") : this.formulario.url_origen;
      //this.cancelTokenSource = axios.CancelToken.source();
      let data = {
        name: this.formulario.nombre,
        phone: this.formulario.telefono,
        email: this.formulario.email,
        zip_code: this.formulario.codigo_postal,
        car_km: this.formulario.kms,
        car_brand: this.formulario.data_matricula.marca,
        car_model: this.formulario.data_matricula.modelo,
        // car_version: this.formulario.data_matricula.version,
        car_year_registration: this.formulario.data_matricula.fecha_matriculacion,
        // financed: this.formulario.financiacion,
        importe: this.formulario.dinero,
        titular: this.formulario.financiacion,
        // titular: this.formulario.titular,
        // ingresos: this.formulario.ingresos,
        legal_advise: this.formulario.acepto1,
        lopd: this.formulario.acepto2,
        url_origen: url_origen,
      };
      setTimeout(() => {
        if (this.formulario.loading == true) 
        { 
          store.commit("setBarraColor2", "azul");
          store.commit("setCheckColor3", "azul-3");
          //this.cancelTokenSource.cancel();
          data.data_matricula = false;

          axios.post(process.env.VUE_APP_AXIOS_BASE_URL + "createSolicitudApiMexico", data)
            .then((data) => {
            
              this.preload2 = true;
              let tokenEnlace = data.data.token_enlace;
              let solicitud = data.data.solicitud;
              let idSolicitud = tokenEnlace.slice(14);
              store.commit("setTokenEnlace", tokenEnlace);
              store.commit("setIdSolicitud", idSolicitud);
              store.commit("setSolicitud", solicitud);
              if (solicitud.numeroestado >= 9000) 
              {
                store.commit("setStep", 10);
                this.$router.push({
                  path: "/solicitud/denegada/" + tokenEnlace,
                  hash: null,
                });
              } 
              else 
              {
                if (solicitud.coche_marca === "") {
                  this.$router.push({
                    path: "/solicitud/coche/mexico/" + tokenEnlace,
                    hash: null,
                  });
                } 
                else {
                  store.commit("setStep", 5);
                  store.commit("setPreload", true);
                  this.$router.push({
                    path: "/formulario/mexico/" + tokenEnlace,
                    hash: null,
                  });
                }
              }
            })
            .then(() => sessionStorage.clear())
            .catch(() => {
              this.formulario.loading = false;
              store.commit("setStep", 3);
            });
        }
      },10000);
      
      await axios.post(process.env.VUE_APP_AXIOS_BASE_URL + "createSolicitudApiMexico", data)
        .then( (response)  => {
          this.preload2 = true;
          let tokenEnlace = response.data.token_enlace
          let solicitud = response.data.solicitud
          let idSolicitud = tokenEnlace.slice(14)
          store.commit("setTokenEnlace", tokenEnlace);
          store.commit("setIdSolicitud", idSolicitud);
          store.commit("setSolicitud", solicitud);
          
          this.$router.push({
                path: "/formulario/" + tokenEnlace,
                hash: null,
              });
                
                if (solicitud.numeroestado >= 9000) {
                  store.commit("setStep", 10);
                  this.$router.push({
                    path: "/solicitud/denegada/" + tokenEnlace,
                    hash: null,
                  });
                } 
                else {
                  if (solicitud.coche_marca === "") {
                    this.$router.push({
                      path: "/solicitud/coche/" + tokenEnlace,
                      hash: null,
                    });
                  } 
                  else {
                    store.commit("setStep", 4);
                    //store.commit("setPreload", true);
                    this.$router.push({
                      path: "/formulario/" + tokenEnlace,
                      hash: null,
                    });
                  }
                }
          })
        .then(() => sessionStorage.clear())
        .catch(() => {
          this.formulario.loading = false;
          store.commit("setStep", 3);
          let confirmacion = confirm("Falla en solicitud, ¿Reiniciar?");
          if (confirmacion) {
            this.$router.push('/');
          } else {
            // Código a ejecutar si el usuario presiona "Cancelar"
            this.$router.push('/formulario/step1');
          }
        });
        // store.commit("resetSolicitud");
    },
    
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
    
  },

  watch: {
    windowWidth(value) {
      const menu = document.querySelector('#navigation_menu');
      if (value >= 992) {
        menu.style.display = 'block';
      }else{
        menu.style.display = 'none';
      }
    },
    formulario(value) {
      console.log(value)
    }
  },
  mounted: function () {
    store.commit("setStep", "3");
    //Set color de las bolitas que indican el cambio de paso
    store.commit("setBarraColor1", "azul");
    store.commit("setCheckColor2", "azul-2");
    store.commit("setBarraColor2", "azul");
    store.commit("setCheckColor3", "azul-3");
    const menu = document.querySelector('#navigation_menu');
    if (window.innerWidth >= 992) {
      menu.style.display = 'block';
    }else{
      menu.style.display = 'none';
    }
    window.addEventListener('resize', this.getWindowWidth);
  },
};
</script>

<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}
input {
  color: #4e789b!important;

}
fieldset{
  margin: 0!important;
}
.autocompletar:deep() .simple-typeahead > input[data-v-f81ca714] {
  width: 100%;
  padding: 10px 20px;
  height: 48px;
  line-height: 22px;
  font-size: 18px;
  border-radius: 6px;
  background: white;
  border: solid 1px #c4c4c4;
  opacity: 0.6;
}

#step3 {
  .select,
  .ibn_input {
    border-radius: 10px;
    border: 2px solid var(--Disabled-c4c4c4, rgba(196, 196, 196, 0.40));
    background-color: rgba(196, 196, 196, 0.1);
    display: flex;
    width: 100% !important;
    height: 48px;
    padding: 11px 15px;
    align-items: center;
    gap: 10px;
    color:#004680;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
 
  .ibn_input:placeholder-shown{
    color: rgba(153, 153, 153, 0.80);
  }
  .ibn_input:focus,  
  .ibt_input:active{
    border-radius: 10px;
    border: 2px solid var(--Disabled-c4c4c4, rgba(196, 196, 196, 0.40));
    background: #ffffff !important; 
    display: flex;
    width: 100%;
    height: 48px;
    padding: 11px 15px;
    align-items: center;
    gap: 10px;
    color:#004680;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .checkbox_label, a {
    display: inline;
    align-items: center;
    color: #004680;
    font-size: small;
  }
  .checkbox {
    width: 1.6em;
    height: 1.6em;
    background: #fff;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid #004680;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  .checkbox:checked {
    background-image: url("../../assets/img/checked_icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
  #image_formulario {
    background-repeat: no-repeat;
    background-size: cover;
  }
  #cards_row_form {
    margin-top: 20px;
  }

  .verMas {
    color: #ffa500;
    font-weight: 600;
    cursor: pointer;
  }

}

@include media-breakpoint-only(xs) {
  #navigation_menu {
    display: none;
  }

  .btn-orange{
    font-size: 18px !important;

    width: 85vw;
  }
  #cards_row_form {
    margin-top: 0px !important;
  }

  #step3 {
    height: auto;
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }

  }
}
@include media-breakpoint-only(sm) {
  #navigation_menu {
    display: none;
  }
  .btn-orange{
    margin-top:23px;
    width: 60vw;
  }

  #step3 {
    height: auto;
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }
  }
}

@include media-breakpoint-only(md) {
  #navigation_menu {
    display: none;
  }
  
  #step3 {
    height: auto;

    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
    }
    .slogan1 {
      color: #004680;
      font-weight: 800;
    }
  }
}

@include media-breakpoint-only(lg) {
  #step3 {
    height: auto;
    margin-top: 80px;
    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
    }
  }
}

@include media-breakpoint-only(xl) {
  #step3 {
    height: auto;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 525px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      margin-top:6%;
    }
  }
}
</style>